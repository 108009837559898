import {
  FC,
  ReactNode,
  useEffect,
  useMemo,
  useState,
} from 'react';
import {
  ButtonGroup,
  Button,
  IconButton,
  Checkbox,
  FormControlLabel,
  Box,
  Tooltip,
} from '@mui/material';
import {
  addDays,
  addMonths,
  addWeeks,
  isSameDay,
  startOfDay,
} from 'date-fns';
import { ChevronLeft, ChevronRight } from '@mui/icons-material';
import PrintIcon from '@mui/icons-material/Print';
import MenuOpenIcon from '@mui/icons-material/MenuOpen';
import { MobileDatePicker } from '@mui/x-date-pickers';
import { useDispatch, useSelector } from 'react-redux';
import { CalendarView, view } from '../../../core/types/util/calendarView';
import { format, getWeek } from '../../../shared/logic/dates';
import { PopMenu } from '../../../shared/components/popMenu';
import { RootState } from '../../../core/redux';
import {
  setShowDummies,
  setShowMachines,
  setShowWeekend,
  setShowWorkers,
  setShowCoreHours,
  setShowEquipment,
  setSortByProject,
  setShowStangelandProsjektWorkers,
} from '../../../core/redux/viewSetting';
import { PopMenuHeader } from '../../popMenuHeader';
import { toggleSidebar } from '../../../core/redux/rightFilterState';
import { useUrl } from '../../../shared/hooks/params';
import { FilterIcon } from '../../../shared/components/filterIcon';

export const TimelineHeaderNav: FC<{
  day?: Date;
  onDayChange?: (date: Date) => void;
  viewPeriod: (date: Date, dir: boolean) => Date;
  dateFormat: (date: Date) => string;
}> = ({
  day = new Date(),
  onDayChange = () => null,
  viewPeriod,
  dateFormat,
}) => {
  const [pickerVal, setPickerVal] = useState<Date>(day);
  useEffect(() => {
    if (!isSameDay(pickerVal, day)) {
      setPickerVal(day);
    }
  }, [day]);

  const sameDay = useMemo(
    () => format(day, 'P') === format(new Date(), 'P'),
    [day],
  );
  return (
    <span className="align-left">
      <ButtonGroup>
        <Button
          variant="contained"
          onClick={() => onDayChange(viewPeriod(day, false))}
        >
          <ChevronLeft />
        </Button>
        <Button
          variant="contained"
          onClick={() => onDayChange(viewPeriod(day, true))}
        >
          <ChevronRight />
        </Button>
      </ButtonGroup>
      <ButtonGroup>
        <Button
          variant="contained"
          sx={{ whiteSpace: 'nowrap' }}
          disabled={sameDay}
          onClick={() => onDayChange(new Date())}
        >
          I dag
        </Button>
      </ButtonGroup>
      <MobileDatePicker
        value={pickerVal}
        onChange={(v) => setPickerVal(v || day)}
        inputFormat="yyyy-MM-dd'T'HH:mm:ss"
        label="Velg dato"
        onAccept={(newValue) => {
          if (newValue) { onDayChange(startOfDay(newValue)); }
        }}
        renderInput={({ inputRef, inputProps }) => (
          <input
            className="date-text"
            readOnly
            id="datePicker"
            ref={inputRef}
            value={dateFormat(new Date(inputProps?.value))}
            onClick={inputProps?.onClick}
          />
        )}
      />
    </span>
  );
};

export const viewPeriodDay = (date: Date, dir: boolean) => startOfDay(addDays(date, dir ? 1 : -1));
export const viewPeriodWeek = (date: Date, dir: boolean) => startOfDay(addWeeks(date, dir ? 1 : -1));
export const viewPeriodMonth = (date: Date, dir: boolean) => startOfDay(addWeeks(date, dir ? 1 : -1));
export const viewPeriodQuarter = (date: Date, dir: boolean) => startOfDay(addMonths(date, dir ? 1 : -1));
export const viewPeriodYear = (date: Date, dir: boolean) => startOfDay(addMonths(date, dir ? 1 : -1));

export const dateFormatDay = (date: Date) => format(date, 'dd. MMMM yyyy');
export const dateFormatWeek = (date: Date) => `uke ${getWeek(date)} ${format(date, 'yyyy')}`;
export const dateFormatMonth = (date: Date) => `uke ${getWeek(date)} ${format(date, 'yyyy')}`;
export const dateFormatQuarter = (date: Date) => format(date, 'MMMM yyyy');
export const dateFormatYear = (date: Date) => format(date, 'MMMM yyyy');

export const TimelineHeader: FC<{
  day?: Date;
  currentView?: CalendarView;
  onDayChange?: (date: Date) => void;
  onCalendarViewChange?: (view: CalendarView) => void;
  filters?: ReactNode;
  filterActive?: boolean;
  showCheckboxOption?: boolean;
  showPrintButton?: boolean;
}> = ({
  day = new Date(),
  currentView = 'dag',
  onDayChange = () => null,
  onCalendarViewChange = () => null,
  filters,
  filterActive,
  showCheckboxOption = false,
  showPrintButton = false,
}) => {
  const [, changeUrl] = useUrl();

  const {
    showWeekend,
    showWorkers,
    showDummies,
    showMachines,
    showEquipment,
    showCoreHours,
    showStangelandProsjektWorkers,
    sortByProject,
  } = useSelector((state: RootState) => state.viewSetting);

  const showSidebar = useSelector(
    (state: RootState) => state.rightFilterState.showSidebar,
  );
  const dispatch = useDispatch();

  const viewPeriod = useMemo(() => {
    if (currentView === 'dag') return viewPeriodDay;
    if (currentView === 'uke') return viewPeriodWeek;
    if (currentView === 'maned') return viewPeriodMonth;
    if (currentView === 'kvartal') return viewPeriodQuarter;
    if (currentView === 'ar') return viewPeriodYear;
    return viewPeriodDay;
  }, [currentView]);

  const dateFormat = useMemo(() => {
    if (currentView === 'dag') return dateFormatDay;
    if (currentView === 'uke') return dateFormatWeek;
    if (currentView === 'maned') return dateFormatMonth;
    if (currentView === 'kvartal') return dateFormatQuarter;
    if (currentView === 'ar') return dateFormatYear;
    return dateFormatDay;
  }, [currentView]);

  return (
    <div className="timeline-header-component">
      <Box
        sx={{
          flex: 1,
          display: 'grid',
          gridTemplateAreas: '"left center right"',
          gridTemplateColumns: '1fr 3fr auto',
          gridGap: '8px',
          '@media (max-width: 1716px)': {
            gridTemplateAreas: '"left right" "center center"',
            gridTemplateColumns: '1fr auto',
            gridGap: '16px',
          },
        }}
      >
        <Box gridArea="left" alignSelf="center">
          <TimelineHeaderNav
            dateFormat={dateFormat}
            viewPeriod={viewPeriod}
            day={day}
            onDayChange={onDayChange}
          />
        </Box>
        {showCheckboxOption && (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'flex-end',
            gridArea: 'center',
            '@media (max-width: 1716px)': {
              justifyContent: 'flex-start',
            },
          }}
        >
          <FormControlLabel
            label="Vis ansatt"
            control={(
              <Checkbox
                checked={showWorkers}
                onChange={() => dispatch(setShowWorkers(!showWorkers))}
              />
            )}
          />
          <FormControlLabel
            label="Vis ansattbehov"
            control={(
              <Checkbox
                checked={showDummies}
                onChange={() => dispatch(setShowDummies(!showDummies))}
              />
            )}
          />
          <FormControlLabel
            label="Vis maskin"
            control={(
              <Checkbox
                checked={showMachines}
                onChange={() => dispatch(setShowMachines(!showMachines))}
              />
            )}
          />
          <FormControlLabel
            label="Vis utstyr"
            control={(
              <Checkbox
                checked={showEquipment}
                onChange={() => dispatch(setShowEquipment(!showEquipment))}
              />
            )}
          />
        </Box>
        )}
        <Box display="flex" gap={1} alignItems="center" gridArea="right">
          {showPrintButton && currentView === 'uke' && (
          <Tooltip title="Vis printbar versjon">
            <IconButton onClick={() => changeUrl(
              '/mannskap/print',
              { view: 'uke' },
              false,
            )}
            >
              <PrintIcon color="primary" />
            </IconButton>
          </Tooltip>
          )}
          <PopMenu
            button={(
              <IconButton color="primary" aria-label="showWeekend" component="span">
                <FilterIcon filterActive={
                  filterActive
                  || (currentView === 'uke' && showCoreHours)
                  || showWeekend
                  || sortByProject
                  || showStangelandProsjektWorkers
                  }
                />
              </IconButton>
            )}
            width={filters === undefined ? '150px' : '300px'}
          >
            <span>
              {filters !== undefined && (
              <span>
                <PopMenuHeader>Filter</PopMenuHeader>
                {filters}
              </span>
              )}
              <Box display="flex" flexDirection="column">
                <PopMenuHeader>Sortering</PopMenuHeader>
                <span>
                  <FormControlLabel
                    label="Sorter etter prosjekt"
                    control={(
                      <Checkbox
                        checked={sortByProject}
                        onChange={() => dispatch(setSortByProject(!sortByProject))}
                      />
                )}
                  />
                </span>
              </Box>
              <Box display="flex" flexDirection="column">
                <PopMenuHeader>Visning</PopMenuHeader>
                <span>
                  <FormControlLabel
                    label="Vis helg"
                    control={(
                      <Checkbox
                        checked={showWeekend}
                        onChange={() => dispatch(setShowWeekend(!showWeekend))}
                      />
                )}
                  />
                </span>
                {currentView === 'uke' && (
                <span>
                  <FormControlLabel
                    label="Vis kun kjernetid"
                    control={(
                      <Checkbox
                        checked={showCoreHours}
                        onChange={() => dispatch(setShowCoreHours(!showCoreHours))}
                      />
                )}
                  />
                </span>
                )}
              </Box>
              <Box display="flex" flexDirection="column">
                <PopMenuHeader>Annet</PopMenuHeader>
                <span>
                  <FormControlLabel
                    label="Vis Stangeland Prosjekt AS"
                    control={(
                      <Checkbox
                        checked={showStangelandProsjektWorkers}
                        onChange={() => dispatch(
                          setShowStangelandProsjektWorkers(!showStangelandProsjektWorkers),
                        )}
                      />
                    )}
                  />
                </span>
              </Box>
            </span>
          </PopMenu>
          <ButtonGroup>
            <Button
              onClick={() => onCalendarViewChange(view.day)}
              variant={currentView === 'dag' ? 'contained' : 'outlined'}
            >
              Dag
            </Button>
            <Button
              onClick={() => onCalendarViewChange(view.week)}
              variant={currentView === 'uke' ? 'contained' : 'outlined'}
            >
              Uke
            </Button>
            <Button
              onClick={() => onCalendarViewChange(view.month)}
              variant={currentView === 'maned' ? 'contained' : 'outlined'}
            >
              Måned
            </Button>
            <Button
              onClick={() => onCalendarViewChange(view.quarter)}
              variant={currentView === 'kvartal' ? 'contained' : 'outlined'}
            >
              Kvartal
            </Button>
            <Button
              onClick={() => onCalendarViewChange(view.year)}
              variant={currentView === 'ar' ? 'contained' : 'outlined'}
            >
              År
            </Button>
          </ButtonGroup>
          <Button
            className="drawer-button"
            color="primary"
            onClick={() => dispatch(toggleSidebar())}
            sx={{
              marginLeft: 2,
              paddingLeft: 1.5,
              borderRadius: '4px 0 0 4px',
              borderTop: 1,
              borderLeft: 1,
              borderBottom: 1,
              maxWidth: '35px',
              minWidth: '35px',
              maxHeight: '40px',
              minHeight: '40px',
              backgroundColor: '#f8f8f8',
            }}
          >
            <MenuOpenIcon
              color="primary"
              sx={{
                fontSize: 25,
                transform: showSidebar ? 'rotate(180deg)' : '',
                transition: 'transform 0.5s',
              }}
            />
          </Button>
        </Box>
      </Box>
    </div>
  );
};

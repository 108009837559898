/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

const stateName = 'viewSetting';

interface InitialState {
  showWeekend: boolean,
  showWorkers: boolean,
  showDummies: boolean,
  showMachines: boolean,
  showEquipment: boolean,
  showCoreHours: boolean,
  showStangelandProsjektWorkers: boolean,
  sortByProject: boolean,
}

const initialState: InitialState = {
  showWeekend: false,
  showWorkers: true,
  showDummies: true,
  showMachines: true,
  showEquipment: true,
  showCoreHours: false,
  showStangelandProsjektWorkers: false,
  sortByProject: false,
};

const viewSettingSlice = createSlice({
  name: stateName,
  initialState,
  reducers: {
    setShowWeekend(state, action: PayloadAction<boolean>) {
      state.showWeekend = action.payload;
    },
    setShowWorkers(state, { payload: showWorkers }: PayloadAction<boolean>) {
      state.showWorkers = showWorkers;
    },
    setShowDummies(state, { payload: showDummies }: PayloadAction<boolean>) {
      state.showDummies = showDummies;
    },
    setShowMachines(state, action: PayloadAction<boolean>) {
      state.showMachines = action.payload;
    },
    setShowEquipment(state, action: PayloadAction<boolean>) {
      state.showEquipment = action.payload;
    },
    setShowCoreHours(state, action: PayloadAction<boolean>) {
      state.showCoreHours = action.payload;
    },
    setShowStangelandProsjektWorkers(state, action: PayloadAction<boolean>) {
      state.showStangelandProsjektWorkers = action.payload;
    },
    setSortByProject(state, action: PayloadAction<boolean>) {
      state.sortByProject = action.payload;
    },
    reset(state) {
      Object.assign(state, initialState);
    },
  },
});

export const {
  setShowWeekend,
  setShowWorkers,
  setShowDummies,
  setShowMachines,
  setShowEquipment,
  setShowCoreHours,
  setShowStangelandProsjektWorkers,
  setSortByProject,
  reset,
} = viewSettingSlice.actions;

export default persistReducer({ key: stateName, storage }, viewSettingSlice.reducer);

import { EventApi } from '@fullcalendar/core';
import {
  addDays,
  addHours,
  startOfDay,
  startOfWeek,
} from 'date-fns';
import { MoveTask } from '../api/moveTask';
import { TaskEvent } from '../types/taskEvent';
import { Task } from '../types/task';
import { UpdateTask } from '../api/updateTask';
import { NewTask } from '../api/newTask';
import { CalendarView } from '../types/util/calendarView';
import { Absence } from '../types/absence';
import { Project } from '../types/project';
import { Worker } from '../types/worker';

export const task2UpdateTask = (task: Task): UpdateTask => ({
  workerId: task.worker?.employeeNumber,
  workerType: task.workerType,
  projectId: task.project?.id ?? 0,
  startTime: task.startTime,
  endTime: task.endTime,
  title: task.title,
  commitPercentage: task.commitPercentage,
  comment: task.comment,
});

export const task2MoveTask = (task: Task): MoveTask => ({
  workerId: task.worker?.employeeNumber,
  workerType: task.workerType,
  projectId: task.project?.id ?? 0,
  startTime: task.startTime,
  endTime: task.endTime,
});

export const eventRecieve2TaskEvent = (e: EventApi, view?: CalendarView, showWeekend: boolean = false): TaskEvent|null => {
  if (!e) return null;
  if (!e.start) return null;
  if (!e.end) return null;
  const resourceId = e.getResources()[0].id;
  let startTime: Date;
  let endTime: Date;
  let date: Date;
  if (view === 'uke') {
    date = startOfDay(addHours(e.start, 1));
    startTime = addHours(date, 7);
    endTime = addHours(date, 15);
  } else if (view === 'kvartal') {
    date = addDays(addHours(startOfDay(e.start), 12), showWeekend ? 2 : 3);
    startTime = startOfWeek(date, { weekStartsOn: 1 });
    endTime = addDays(startOfWeek(date, { weekStartsOn: 1 }), 5);
  } else {
    startTime = e.start;
    endTime = e.end;
  }
  return {
    id: e.id,
    startTime,
    endTime,
    workerType: e.extendedProps.workertype,
    isMachine: `${e.extendedProps.isMachine}` === 'true',
    isEquipment: `${e.extendedProps.isEquipment}` === 'true',
    parent: parseInt(resourceId, 10) || resourceId,
    title: e.title,
    comment: e.extendedProps.comment,
    type: e.extendedProps.type,
  };
};

/** Create a new empty project object with the given ID */
export const emptyProject = (id: number): Project => ({
  id,
  projectName: '',
  status: 'Aktivt',
  latitude: '',
  longitude: '',
  startDate: '1970-01-01T00:00:00',
  expectedEndDate: '1970-01-01T00:00:00',
  contractAmount: 0,
  builder: '',
  client: '',
  contact: {
    name: '',
    phone: '',
    eMail: '',
  },
  scopeOfWork: '',
  internalProduction: [],
  favourite: false,
});

/** Create a new empty worker object with the given ID */
export const emptyWorker = (employeeNumber: number): Worker => ({
  employeeNumber,
  firstName: '',
  lastName: '',
  fullName: '',
  company: '',
  department: '',
  laborStatus: '',
  dateOfEmployment: '1970-01-01T00:00:00',
  lastWorkingDay: '1970-01-01T00:00:00',
  lastEmploymentDay: '1970-01-01T00:00:00',
  path: '',
  expiredCertifications: 0,
  missingSecurityTraining: 0,
  parentOrganizationId: 0,
});

export const newTask2Task = (id: string, task: NewTask): Task => ({
  id,
  worker: task.workerId
    ? emptyWorker(task.workerId)
    : undefined,
  project: task.projectId
    ? emptyProject(task.projectId)
    : undefined,
  status: task.type === 'Unavailability' ? 'Created' : 'Approved',
  startTime: task.startTime,
  endTime: task.endTime,
  title: task.title,
  commitPercentage: 100,
  createdTimestamp: '1970-01-01T00:00:00',
  createdBy: '',
  createdByName: '',
  createdByEmail: '',
  lastEditBy: '',
  lastEditedByName: '',
  lastEditedByEmail: '',
  lastEditTime: '1970-01-01T00:00:00',
  type: task.type ?? 'Normal',
});

export const absence2Task = (absence: Absence): Task => ({
  ...absence,
  type: 'Unavailability',
  status: 'Created',
  startTime: absence.start,
  endTime: absence.end,
  title: 'Avtalt fravær',
  commitPercentage: 100,
  lastEditedByEmail: absence.lastEditedByEmail,
});

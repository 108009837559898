import {
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { Box, Checkbox, FormControlLabel } from '@mui/material';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import { formatISO, isAfter } from 'date-fns';
import { Header } from '../../components/header';
import { Modal } from '../../shared/components/modal';
import { MultiSearchSelect } from '../../shared/components/multiSearchSelect';
import { Page } from '../../shared/components/page';
import { Planner } from '../../components/planner';
import { ProjectDetails } from '../../components/projectDetails';
import { ProjectFavourite } from '../../components/projectFavourite';
import { WorkerDetails } from '../../components/workerDetails';
import { NewTask } from '../../core/api/newTask';
import { task2MoveTask } from '../../core/helpers/converters';
import {
  labelSort,
  editMachineRules,
  editEquipmentRules,
} from '../../core/helpers/functions';
import {
  eventBackgroundColor,
  eventDefaultEnd,
  eventDefaultStart,
} from '../../core/helpers/taskHelpers';
import { useDispatch, useSelector } from '../../core/hooks/redux';
import { useCombinedLoading } from '../../core/hooks/useCombinedLoading';
import {
  setIncludeInternalProduction,
  setIncludeOwnProjects,
  setProjectManagers,
  setShowAllWorkers,
  setShowOnlyPlannedProjects,
  useOrganizations,
} from '../../core/redux/leftFilterState';
import { setProjectLeaders } from '../../core/redux/rightFilterState';
import { setProjectWorkers } from '../../core/redux/taskFilterState';
import {
  useGetAllWorkersQuery,
  useGetAllProjectsQuery,
} from '../../core/redux/worker';
import { CalendarView } from '../../core/types/util/calendarView';
import { SelectItem } from '../../shared/types/util/selectItem';
import { TaskEvent } from '../../core/types/taskEvent';
import { PopMenuHeader } from '../../components/popMenuHeader';
import { useDummies } from '../../core/hooks/useDummies';
import { useTasks } from '../../core/hooks/useTasks';
import {
  useGetAllMachineAssignmentsQuery,
  usePostMachineOrderMutation,
  usePutMoveMachineOrderMutation,
} from '../../shared/redux/machine';
import { useTimeFrameStrings } from '../../core/hooks/useTimeframe';
import { Event } from '../../core/types/util/event';
import { RootState } from '../../core/redux';
import { MoveMachineOrder } from '../../shared/types/api/moveMachineOrder';
import { useHasRoles } from '../../core/helpers/useHasRoles';
import {
  useGetAllEquipmentAssignmentsQuery,
  usePostEquipmentOrderMutation,
  usePutMoveEquipmentOrderMutation,
} from '../../shared/redux/rental';
import { MoveEquipmentOrder } from '../../shared/types/api/moveEquipmentOrder';
import './style.scss';
import { allowedEventMovement } from '../../shared/logic/functions';
import { Displayable } from '../../shared/types/util/displayable';

export const ProjectPlanner = () => {
  const [selectedProject, setSelectedProject] = useState<{
    id: number;
    title: string;
  } | null>(null);
  const [selectedWorker, setSelectedWorker] = useState<{
    id: number | string;
    title: string;
  } | null>(null);
  const organizations = useOrganizations();
  const dummies = useDummies();
  const timeFrame = useTimeFrameStrings();
  const dispatch = useDispatch();

  const leftProjectIncludeInternalProduction = useSelector((s) => s.leftFilterState.project.includeInternalProduction)
    || false;
  const leftProjectIncludeOwnProjects = useSelector((s) => s.leftFilterState.project.includeOwnProjects) || false;
  const leftProjectShowOnlyPlannedProjects = useSelector((s) => s.leftFilterState.project.showOnlyPlannedProjects)
    || false;
  const leftManagerFilter = useSelector((s) => s.leftFilterState.project.managers) || [];
  const leftProjectSearch = useSelector((s) => s.leftFilterState.project.search) || '';
  const showAllWorkers = useSelector(
    (state: RootState) => state.leftFilterState.worker.showAllWorkers,
  );
  const taskWorkerFilter = useSelector((s) => s.taskFilterState.project.workers) || [];
  const rightLeaderFilter = useSelector((l) => l.rightFilterState.project.leaders) || [];
  const showWorkers = useSelector(
    (state: RootState) => state.viewSetting.showWorkers,
  );
  const showDummies = useSelector(
    (state: RootState) => state.viewSetting.showDummies,
  );
  const showMachines = useSelector(
    (state: RootState) => state.viewSetting.showMachines,
  );
  const showEquipment = useSelector(
    (state: RootState) => state.viewSetting.showEquipment,
  );
  const showStangelandProsjektWorkers = useSelector(
    (state: RootState) => state.viewSetting.showStangelandProsjektWorkers,
  );

  const isActiveOrgsEmpty = organizations.length === 0;

  const {
    data: projects,
    isLoading: leftLoading,
    isFetching: projectsFetching,
  } = useGetAllProjectsQuery(
    {
      organizations,
      includeInternalProduction: leftProjectIncludeInternalProduction,
      includeOwnProjects: isActiveOrgsEmpty
        ? true
        : leftProjectIncludeOwnProjects,
    },
    { pollingInterval: 60000 },
  );
  const {
    data: workers,
    isLoading: rightLoading,
    isFetching: workersFetching,
  } = useGetAllWorkersQuery((showWorkers || showDummies) ? { organizations } : skipToken);
  const {
    data: machines,
    refetch: refetchMachines,
    isLoading: machinesLoading,
    isFetching: machinesFetching,
  } = useGetAllMachineAssignmentsQuery(showMachines ? timeFrame : skipToken, { pollingInterval: 30000 });
  const {
    data: equipment,
    refetch: refetchEquipment,
    isLoading: equipmentLoading,
    isFetching: equipmentFetching,
  } = useGetAllEquipmentAssignmentsQuery(showEquipment ? timeFrame : skipToken, { pollingInterval: 30000 });
  const [moveMachineOrder] = usePutMoveMachineOrderMutation();
  const [createMachineOrder] = usePostMachineOrderMutation();
  const [moveEquipmentOrder] = usePutMoveEquipmentOrderMutation();
  const [createEquipmentOrder] = usePostEquipmentOrderMutation();
  const {
    tasks, isLoading: taskLoading, add, move, remove,
  } = useTasks();

  const anyFetching = useCombinedLoading(
    workersFetching,
    projectsFetching,
    machinesFetching,
    equipmentFetching,
  );

  const anyLoading = useCombinedLoading(
    leftLoading,
    rightLoading,
    taskLoading,
    machinesLoading,
    equipmentLoading,
  );

  const canEdit = useHasRoles(['admin', 'super-admin', 'project-owner', 'writer']);

  // TODO: lage generisk funksjon
  const isMachineOrder = (id: string) => machines?.some((m) => m.id === id && m.type === 'Order');
  const isMachineApprovedAssignment = (id: string) => machines?.some(
    (m) => m.id === id && m.type === 'Assignment' && m.status === 'Approved',
  );
  const isMachineAssignment = (id: string) => machines?.some((m) => m.id === id && m.type === 'Assignment');

  const isEquipmentOrder = (id: string) => equipment?.some((e) => e.id === id && e.type === 'Order');
  const isEquipmentApprovedAssignment = (id: string) => equipment?.some(
    (e) => e.id === id && e.type === 'Assignment' && e.status === 'Approved',
  );
  const isEquipmentAssignment = (id: string) => equipment?.some((e) => e.id === id && e.type === 'Assignment');

  const onEventAdded = (e: TaskEvent, v: CalendarView) => {
    const body: NewTask = {
      workerId: e.workerType ? undefined : parseInt(e.id, 10),
      projectId: e.parent as number,
      startTime: eventDefaultStart(e.startTime, v),
      endTime: eventDefaultEnd(e.endTime, v),
      workerType: e.workerType,
      title: 'test',
    };
    add(body, `${e.title}`);
  };

  const moveTask = useCallback(
    (e: TaskEvent) => {
      if (isMachineOrder(e.id)) {
        const machineOrder = machines?.find((m) => m.id === e.id);
        if (!machineOrder) return;
        const body: MoveMachineOrder = {
          from: formatISO(e.startTime),
          to: formatISO(e.endTime),
          category: machineOrder?.category,
          subCategory: machineOrder?.subCategory,
          projectId: e.parent as number,
          comment: machineOrder?.orderComment,
          machineInternalNumber: machineOrder.machine?.internalNumber,
        };
        moveMachineOrder({ id: e.id, body });
      } else if (isEquipmentOrder(e.id)) {
        const equipmentOrder = equipment?.find((eq) => eq.id === e.id);
        if (!equipmentOrder) return;
        const body: MoveEquipmentOrder = {
          from: formatISO(e.startTime),
          to: formatISO(e.endTime),
          category: equipmentOrder?.category,
          subCategory: equipmentOrder?.subCategory,
          projectId: e.parent as number,
          worksite: equipmentOrder?.worksite,
          comment: equipmentOrder?.orderComment,
          equipmentInternalNumber: equipmentOrder.equipment?.internalNumber,
          ordererEmployeeNumber: equipmentOrder.ordererEmployeeNumber as number,
        };
        moveEquipmentOrder({ id: e.id, body });
      } else if (isMachineApprovedAssignment(e.id)) {
        const machineAssignment = machines?.find((m) => m.id === e.id);
        createMachineOrder({
          from: formatISO(e.startTime),
          to: formatISO(e.endTime),
          assignmentId: e.id,
          machineInternalNumber:
            machineAssignment?.machine.internalNumber ?? '',
          projectId: e.parent as number,
          comment: machineAssignment?.comment || '',
        });
      } else if (isEquipmentApprovedAssignment(e.id)) {
        const equipmentAssignment = equipment?.find((eq) => eq.id === e.id);
        createEquipmentOrder({
          from: formatISO(e.startTime),
          to: formatISO(e.endTime),
          assignmentId: e.id,
          equipmentInternalNumber:
            equipmentAssignment?.equipment.internalNumber ?? '',
          projectId: e.parent as number,
          comment: equipmentAssignment?.comment || '',
          ordererEmployeeNumber: equipmentAssignment?.ordererEmployeeNumber as number,
        });
      } else if (isMachineAssignment(e.id)) {
        const assignment = machines?.find((m) => m.id === e.id);
        if (!assignment || !assignment.order) return;
        const body: MoveMachineOrder = {
          from: formatISO(e.startTime),
          to: formatISO(e.endTime),
          category: assignment?.category,
          subCategory: assignment?.subCategory,
          projectId: e.parent as number,
          comment: assignment?.orderComment,
          machineInternalNumber: assignment.machine?.internalNumber,
        };
        moveMachineOrder({ id: assignment.order.id, body });
      } else if (isEquipmentAssignment(e.id)) {
        const assignment = equipment?.find((eq) => eq.id === e.id);
        if (!assignment || !assignment.order) return;
        const body: MoveEquipmentOrder = {
          from: formatISO(e.startTime),
          to: formatISO(e.endTime),
          category: assignment?.category,
          subCategory: assignment?.subCategory,
          projectId: e.parent as number,
          worksite: assignment?.worksite,
          comment: assignment?.orderComment,
          equipmentInternalNumber: assignment.equipment?.internalNumber,
          ordererEmployeeNumber: assignment.ordererEmployeeNumber as number,
        };
        moveEquipmentOrder({ id: assignment.order.id, body });
      } else {
        const task = tasks?.find((t) => `${t.id}` === `${e.id}`);
        if (!task) return;
        const updateTaskData = task2MoveTask(task);
        updateTaskData.startTime = formatISO(e.startTime);
        updateTaskData.endTime = formatISO(e.endTime);
        updateTaskData.projectId = e.parent as number;
        move(`${e.id}`, updateTaskData, `${e.title}`);
      }
    },
    [tasks, machines, equipment],
  );

  const plannerWorkers = useMemo(() => {
    const work = workers
      ?.filter(
        (w) => rightLeaderFilter.length === 0
            || rightLeaderFilter.some((l) => l.id === w.leaderId),
      )
      ?.filter((w) => (!showStangelandProsjektWorkers ? w.company === 'Stangeland Maskin AS' : true))
      ?.map((e): Displayable => ({
        ...e,
        id: e.employeeNumber,
        label: `${e.fullName} - ${e.jobRole}`,
        jobRole: e.jobRole,
        expiredCertifications: e.expiredCertifications,
        missingSecurityTraining: e.missingSecurityTraining,
      })) || [];
    return work;
  }, [workers, rightLeaderFilter, showStangelandProsjektWorkers]);

  const projectManagers = useMemo(() => {
    const names = new Map<number, string>();
    projects?.forEach((p) => {
      if (p.operationsManager) {
        names.set(
          p.operationsManager.employeeNumber,
          p.operationsManager.fullName,
        );
      }
    });
    return Array.from(names, ([id, label]) => ({ id, label })).sort(labelSort);
  }, [projects]);

  const activeLeaders = useMemo(() => {
    const items = new Map<number, string>();
    workers?.forEach((w) => {
      if (
        w.leaderId
        && w.leader
        && plannerWorkers.some((p) => p.id === w.leaderId)
      ) {
        items.set(w.leaderId, w.leader);
      }
    });
    return Array.from(items, ([id, label]) => ({ id, label })).sort(labelSort);
  }, [workers]);

  const plannerTasks = useMemo(() => {
    const workerTask: Event[] = !tasks
      ? []
      : tasks
        .filter((t) => t.type === 'Normal')
        .filter((t) => (!showWorkers ? t.workerType : true))
        .filter((t) => (!showDummies ? !t.workerType : true))
        .filter((t) => (!showAllWorkers
          ? organizations.some((o) => t.worker?.parentOrganizationId === o) : true))
        .map((t) => ({
          id: t.id,
          label: t.worker?.employeeNumber
            ? `${t.worker?.fullName} - ${t.worker?.jobRole}`
            : dummies?.find((d) => `${d.id}` === t.workerType)?.label
                || t.workerType
                || '',
          start: new Date(t.startTime),
          end: new Date(t.endTime),
          parent: t.project?.id.toString() ?? '0',
          origin: t.worker?.employeeNumber.toString() || t.workerType || '0',
          color: eventBackgroundColor(
            t.worker?.employeeNumber,
            t.type,
            t.status,
          ),
          editable:
              t.status !== 'New'
              && (t.worker?.employeeNumber !== undefined
                || t.workerType !== undefined),
          resourceEditable:
              t.status !== 'New'
              && (t.worker?.employeeNumber !== undefined
                || t.workerType !== undefined),
          ghost:
              taskWorkerFilter.length > 0
              && !taskWorkerFilter.some((w) => w.id === t.worker?.employeeNumber),
          type: t.type,
          title: t.title,
          comment: t.comment,
          sortOrder: 1,
        }));
    const machineTask: Event[] = !machines || !showMachines
      ? []
      : machines.map((m) => {
        const can = editMachineRules(false, m, canEdit);
        let title = m.subCategory;
        if (m.type !== 'Order') {
          const titles = [
            m.subCategory,
            m.machine?.modelName,
          ];
          if (m.machine.substituteDriverName) {
            titles.push(`(${m.machine.substituteDriverName})`);
          } else if (m.machine.assignedDriver) {
            titles.push(m.machine.assignedDriver.fullName);
          }
          title = titles.join(' - ');
        }
        return {
          id: m.id,
          label: m.subCategory,
          start: new Date(m.from),
          end: new Date(m.to),
          parent: m.project.id.toString(),
          origin: m.subCategory,
          color: m.status === 'Approved' ? '#007994' : '#f29c0c',
          editable: false,
          resourceEditable:
                m.type !== 'Assignment'
                || (m.order?.type === 'New' && m.status !== 'Approved'),
          durationEditable: can.editTime,
          isMachine: true,
          itemMainCategory: m.category,
          type: 'Normal',
          title,
          comment: m.orderComment,
          sortOrder: 2,
        };
      });
    const equipmentTask: Event[] = !equipment || !showEquipment
      ? []
      : equipment.map((e) => {
        const can = editEquipmentRules(false, e, canEdit);
        let titles: (string|undefined)[] = [];
        if (e.type === 'Order') {
          titles = [
            e.subCategory ?? e.order.subCategory,
            e.orderer?.fullName,
          ];
        } else {
          titles = [
            e.subCategory ?? e.order.subCategory,
            e.equipment?.modelName,
            e.orderer?.fullName,
          ];
          if (e.type === 'Assignment') titles.push(`${e.equipment.dailyRentalPrice || 0} kr/dag`);
        }
        const title = titles.filter((t) => !!t).join(' - ') || 'Internutleie';
        const start = e.handOutDate ? new Date(e.handOutDate) : new Date(e.from);
        const end = (() => {
          if (e.status === 'Approved' && !e.handInDate && isAfter(new Date(), new Date(e.to))) {
            return new Date();
          }
          if (e.handInDate) return new Date(e.handInDate);
          return new Date(e.to);
        })();
        return {
          id: e.id,
          label: e.subCategory,
          start,
          end,
          parent: e.project.id.toString(),
          origin: e.subCategory,
          color: e.status === 'Approved' ? '#00a191' : '#e9bd0b',
          ...allowedEventMovement(
            // allowEdit
            can.editTime,
            // allowStart
            !e.handOutDate,
            // allowEnd
            !e.handInDate,
            // allowHorizontal
            (!e.handInDate && !e.handOutDate) && (e.type !== 'Assignment'
                || (e.order?.type === 'New' && e.status !== 'Approved')),
            // allowVertical
            (!e.handInDate && !e.handOutDate) && (e.type !== 'Assignment'
                || (e.order?.type === 'New' && e.status !== 'Approved')),
          ),
          isEquipment: true,
          itemMainCategory: e.category,
          type: 'Normal',
          title,
          comment: e.orderComment,
          dailyRentalPrice: e.type === 'Assignment' ? `${e.equipment?.dailyRentalPrice || 0}` : undefined,
          sortOrder: 3,
        };
      });
    return [...workerTask, ...machineTask, ...equipmentTask];
  }, [
    tasks,
    dummies,
    machines,
    equipment,
    taskWorkerFilter,
    showWorkers,
    showDummies,
    showMachines,
    showEquipment,
    showAllWorkers,
  ]);

  const plannerProjects = useMemo(() => {
    if (!projects) return [];
    return (
      projects
        .filter(
          (p) => leftManagerFilter.length <= 0
            || leftManagerFilter.some(
              (l) => `${l.id}` === `${p.operationsManager?.employeeNumber}`,
            ),
        )
        .filter((p) => (leftProjectShowOnlyPlannedProjects
          ? plannerTasks.some((t) => `${t.parent}` === `${p.id}`)
          : true))
        .filter(
          (p) => leftProjectSearch === ''
            || p.projectName
              .toLocaleLowerCase()
              .includes(leftProjectSearch.toLocaleLowerCase())
            || `${p.id}`.includes(leftProjectSearch),
        )
        .map((p) => ({
          ...p,
          id: p.id,
          responsible_id: p.responsible?.employeeNumber,
          responsible_name: p.responsible?.fullName,
          label: `${p.projectName}`,
        })) || []
    );
  }, [
    projects,
    leftManagerFilter,
    leftProjectSearch,
    leftProjectShowOnlyPlannedProjects,
    plannerTasks,
  ]);

  const workerSelectItems = useMemo(() => {
    if (!workers) return [];
    return (
      workers
        .map(
          (w): SelectItem => ({
            id: w.employeeNumber,
            label: w.fullName,
          }),
        )
        .sort(labelSort) || []
    );
  }, [workers]);

  /**
   * Clear filters that don't apply when organization changes
   */
  useEffect(() => {
    if (anyLoading) return;
    dispatch(
      setProjectManagers(
        leftManagerFilter.filter((l) => projectManagers.some((p) => p.id === l.id)),
      ),
    );
    dispatch(
      setProjectWorkers(
        taskWorkerFilter.filter((w) => workerSelectItems.some((i) => i.id === w.id)),
      ),
    );
    dispatch(
      setProjectLeaders(
        rightLeaderFilter.filter((l) => activeLeaders.some((i) => i.id === l.id)),
      ),
    );
  }, [
    organizations,
    projectManagers,
    workerSelectItems,
    activeLeaders,
    anyLoading,
  ]);

  return (
    <Page className="transportplanlegger-page">
      <Header page="project" />
      <Planner
        page="project"
        tasks={plannerTasks}
        machines={machines}
        equipment={equipment}
        refetchMachines={refetchMachines}
        refetchEquipment={refetchEquipment}
        fetching={anyFetching}
        taskLoading={taskLoading}
        left={plannerProjects}
        right={plannerWorkers}
        leftLoading={leftLoading}
        rightLoading={rightLoading}
        onEventChanged={moveTask}
        onEventAdded={onEventAdded}
        onEventRemoved={remove}
        onLeftSelect={(id, title) => setSelectedProject({ id, title })}
        onRightSelect={(id, title) => setSelectedWorker({ id, title: `${id} ${title.split('-', 1)}` })}
        rightFilters={(
          <Box>
            <PopMenuHeader>Filter</PopMenuHeader>
            <MultiSearchSelect
              label="Leder"
              size="small"
              value={rightLeaderFilter}
              onChange={(l) => dispatch(setProjectLeaders(l))}
            >
              {activeLeaders}
            </MultiSearchSelect>
          </Box>
        )}
        leftFilters={(
          <>
            <span>
              <MultiSearchSelect
                label="Driftsleder"
                size="small"
                value={leftManagerFilter}
                onChange={(p) => dispatch(setProjectManagers(p))}
              >
                {projectManagers}
              </MultiSearchSelect>
            </span>
            <Box
              display="flex"
              flexDirection="column"
              marginTop={0.5}
              alignItems="left"
              marginLeft={0.1}
            >
              <FormControlLabel
                label="Vis kun planlagte"
                control={(
                  <Checkbox
                    checked={leftProjectShowOnlyPlannedProjects}
                    onChange={() => dispatch(
                      setShowOnlyPlannedProjects(
                        !leftProjectShowOnlyPlannedProjects,
                      ),
                    )}
                  />
                )}
              />
              <FormControlLabel
                label="Vis egne"
                disabled={isActiveOrgsEmpty}
                control={(
                  <Checkbox
                    checked={
                      isActiveOrgsEmpty ? false : leftProjectIncludeOwnProjects
                    }
                    onChange={() => dispatch(
                      setIncludeOwnProjects(!leftProjectIncludeOwnProjects),
                    )}
                  />
                )}
              />
              <FormControlLabel
                label="Vis internproduksjon"
                disabled={isActiveOrgsEmpty}
                control={(
                  <Checkbox
                    checked={
                      isActiveOrgsEmpty
                        ? false
                        : leftProjectIncludeInternalProduction
                    }
                    onChange={() => dispatch(
                      setIncludeInternalProduction(
                        !leftProjectIncludeInternalProduction,
                      ),
                    )}
                  />
                )}
              />
              <FormControlLabel
                label="Vis alle ansatte"
                disabled={isActiveOrgsEmpty}
                control={(
                  <Checkbox
                    checked={isActiveOrgsEmpty ? false : showAllWorkers}
                    onChange={() => dispatch(setShowAllWorkers(!showAllWorkers))}
                  />
              )}
              />
            </Box>
          </>
        )}
        taskFilters={{
          value: (
            <span>
              <MultiSearchSelect
                label="Ansatt"
                size="small"
                value={taskWorkerFilter}
                onChange={(p) => dispatch(setProjectWorkers(p))}
              >
                {workerSelectItems}
              </MultiSearchSelect>
            </span>
          ),
          active: taskWorkerFilter.length > 0,
        }}
      />

      {selectedProject !== null && (
        <Modal
          title={(
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
              {selectedProject.title}
              <ProjectFavourite projectId={selectedProject.id} />
            </Box>
          )}
          onClose={() => setSelectedProject(null)}
        >
          <ProjectDetails id={selectedProject.id} />
        </Modal>
      )}
      {selectedWorker !== null && (
        <Modal
          title={selectedWorker.title}
          compactHeader
          onClose={() => setSelectedWorker(null)}
        >
          <WorkerDetails id={selectedWorker.id as number} />
        </Modal>
      )}
    </Page>
  );
};
